import { Typography, Box } from "@material-ui/core";
import NewLeadCTA from "./creation-process/new-lead-cta";
import { getMode } from "../../store/selectors/contact.selectors";
import { useReduxSelector } from "../../store/selectors";
import { Fragment } from "react";

const CreateRealEstate = () => {
    const mode = useReduxSelector(getMode);

    return (
        <Fragment>
            {mode === "default" ? (
                <Box textAlign={"center"} style={{ margin: 24 }}>
                    <Typography style={{ color: "#32325D", fontSize: 17, fontWeight: 600 }}>
                        Loggen Sie sich bitte über den maklerspezifischen Link aus der Mail an, um eine Immobilie anzulegen.
                    </Typography>
                </Box>
            ) : (
                <Box textAlign={"center"}>
                    <Typography style={{ color: "#32325D", fontSize: 17, fontWeight: 600, marginBottom: 17, marginTop: 16 }}>
                        Sie haben noch keine Immobilie angelegt
                    </Typography>
                    <NewLeadCTA />
                </Box>
            )}
        </Fragment>
    );
};
export default CreateRealEstate;
