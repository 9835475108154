import { Typography, makeStyles, createStyles, Avatar, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { useReduxSelector } from "../../store/selectors";
import { getResponsibleUser } from "../../store/selectors/real-estate.selectors";
import { getAgentEmail, getAgentImage, getAgentName, getAgentPhone, getDefaultImage, getAgentGender } from "../../store/selectors/agent.selector";
import { getSignatureByUser } from "@onpreo/toolbox/src/client";

const useStyles = makeStyles(theme =>
    createStyles({
        rootContainer: {
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            color: "#FFFFFF",
            alignItems: "center"
        },
        avatarWrapper: {
            margin: "auto",
            background: theme.palette.grey[300],
            border: "2px solid #FFFFFF",
            width: 150,
            height: 150,
            marginTop: -75
        },
        mobileAvatarWrapper: {
            margin: "auto",
            background: theme.palette.grey[300],
            border: "2px solid #FFFFFF",
            width: 100,
            height: 100,
            marginTop: 0
        },
        text: {
            fontSize: 14,
            fontWeight: 400
        },
        infoWrapper: {
            marginLeft: theme.spacing(4),
            textAlign: "left"
        },
        mobileInfoWrapper: {
            marginLeft: 0,
            marginTop: theme.spacing(2),
            textAlign: "center",
            width: "100%"
        }
    })
);

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const classes = useStyles();
    const agentName = useReduxSelector(getAgentName);
    const agentPhone = useReduxSelector(getAgentPhone);
    const agentEmail = useReduxSelector(getAgentEmail);
    const agentImage = useReduxSelector(getAgentImage);
    const agentGender = useReduxSelector(getAgentGender);
    const defaultImage = useReduxSelector(getDefaultImage);

    const responsibleUser = useReduxSelector(getResponsibleUser);
    const { image: userImage, name, phone, email, gender } = getSignatureByUser(responsibleUser);
    const image = userImage?.src || agentImage?.src || defaultImage?.src;

    const getSalutation = () => {
        let result;
        if (name) {
            gender === "Frau" ? (result = "Ihre Maklerin") : gender === "Herr" ? (result = "Ihr Makler") : (result = "Ihr/e Makler/-in");
        } else {
            agentGender === "Frau" ? (result = "Ihre Maklerin") : agentGender === "Herr" ? (result = "Ihr Makler") : (result = "Ihr/e Makler/-in");
        }

        return result;
    };

    return (
        <Grid container justifyContent={"center"} style={{ marginTop: isMobile ? 0 : 125 }}>
            <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                className={classes.rootContainer}
                style={{
                    width: "100%",
                    position: isMobile ? "static" : "fixed",
                    bottom: 0,
                    zIndex: 10
                }}
            >
                <Grid item>
                    <Avatar src={image || "/makler.jpeg"} alt="Makler picture" className={isMobile ? classes.mobileAvatarWrapper : classes.avatarWrapper} />
                </Grid>

                <Grid item className={isMobile ? classes.mobileInfoWrapper : classes.infoWrapper}>
                    <Typography className={classes.text}>{getSalutation()}</Typography>
                    <Typography className={classes.text}>{name || agentName || "-"}</Typography>
                </Grid>

                <Grid item className={isMobile ? classes.mobileInfoWrapper : classes.infoWrapper}>
                    <Typography className={classes.text}>Telefon</Typography>
                    <Typography className={classes.text}>{phone || agentPhone || "-"}</Typography>
                </Grid>

                <Grid item className={isMobile ? classes.mobileInfoWrapper : classes.infoWrapper}>
                    <Typography className={classes.text}>E-Mail</Typography>
                    <Typography className={classes.text}>{email || agentEmail || "-"}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer;
