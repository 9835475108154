export const NOT_ALLOWED_TEXT = {
    ONE_CLICK_DEAL: {
        title: "Geben Sie die erforderlichen Informationen aus dem vorherigen Schritt so bald wie möglich ein, um fortzufahren:",
        description: "Beauftragen Sie Ihren Makler mit nur einem Klick."
    },
    DEAL_CONFIRMED: {
        title: "Ihr Auftrag ist erfolgreich beim Makler eingegangen! Sie erhalten in Kürze eine Bestätigungs-Mail von uns.",
        description:
            "Damit der Verkaufsprozess schnellstmöglich beginnen kann, empfehlen wir Ihnen, die benötigten Daten alsbald einzutragen, sodass wir Ihr Exposé fertigstellen können."
    },
    OVERVIEW_COMPLETE: {
        title: "Ihre Anfrage wird von einem Makler bearbeitet, nach der Bearbeitung erhalten Sie eine E-Mail und können mit dem nächsten Schritt fortfahren.",
        description: "Hier finden Sie alle wichtigen Daten für künftige Verträge und Termine beim Notar."
    },
    OVERVIEW_NOT_COMPLETE: {
        title: "Bitte gehen Sie zu und schließen Sie den vorherigen Schritt ab, um so schnell wie möglich loszulegen.",
        description:
            "Damit der Verkaufsprozess schnellstmöglich beginnen kann, empfehlen wir Ihnen, die benötigten Daten alsbald einzutragen, sodass wir Ihr Exposé fertigstellen können."
    }
};
