import { Button, Card, CardContent, createStyles, Grid, makeStyles, Typography, useMediaQuery, useTheme, Tooltip } from "@material-ui/core";
import { APP_BAR_HEIGHT } from "../../components/navigation";
import { useRouter } from "next/router";
import { getMode } from "../../store/selectors/contact.selectors";
import { useReduxSelector } from "../../store/selectors";

const useStyles = makeStyles(theme =>
    createStyles({
        nextStepCard: {
            width: "100%"
        },
        nextStepText: {
            color: "#32325D",
            fontSize: "17px",
            fontWeight: 600,
            lineHeight: "23px"
        },
        nextStepInputGrid: {
            marginTop: theme.spacing(1)
        },
        buttonGrid: {
            maxWidth: 500,
            marginTop: theme.spacing(2)
        }
    })
);

const NextStepsCard = () => {
    const classes = useStyles();
    const router = useRouter();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
    const mode = useReduxSelector(getMode);

    return (
        <Card className={classes.nextStepCard}>
            <CardContent>
                <Grid>
                    <Typography className={classes.nextStepText}>Nächster Schritt</Typography>
                </Grid>
                <Grid className={classes.nextStepInputGrid}>
                    <Typography variant={"h4"}>-- Makler beauftragen --</Typography>
                    <Typography>
                        Damit Ihr Makler mit der Vermarktung Ihrer Immobilie loslegen kann, müssen Sie zunächst den Maklerauftrag abschließen.
                    </Typography>
                </Grid>
                <Grid
                    container
                    justifyContent={"flex-start"}
                    alignItems={"flex-end"}
                    className={classes.buttonGrid}
                    direction={isMobile ? "column" : "row"}
                    spacing={2}
                >
                    <Grid item>
                        <Tooltip title={mode === "demo" ? "Im Demo-Modus nicht verfügbar" : ""}>
                            <Button
                                onClick={() => router.push("/deal")}
                                variant={"contained"}
                                color="primary"
                                style={{ width: isMobile ? 210 : "inherit" }}
                            >
                                Zum Maklerauftrag
                            </Button>
                        </Tooltip>
                    </Grid>
                    {/* <Grid item>
                        <Button
                            onClick={() => router.push("/real-estate-status")}
                            variant={"contained"}
                            color="secondary"
                            style={{ width: isMobile ? 210 : "inherit" }}
                        >
                            Alle Schritte ansehen
                        </Button>
                    </Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default NextStepsCard;
