import { Button, Card, CardContent, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { useRouter } from "next/router";
import { useReduxSelector } from "../../store/selectors";
import { getCurrency, getRealEstate, getRealEstateOptions } from "../../store/selectors/real-estate.selectors";
import { HouseOutlined } from "@material-ui/icons";
import { Currency, CurrencyShort, parseAddress, parseCategory } from "../../utils/formatters";
import CreateRealEstate from "../../components/create-real-estate";

const useStyles = makeStyles(theme =>
    createStyles({
        infoCard: {
            width: "100%"
            // minHeight: 150
        },
        avatar: {
            width: 80,
            height: 80
        },
        cardTitleStyle: {
            fontWeight: 600,
            fontSize: "15px"
        },
        cardTextStyle: {
            fontWeight: 400,
            fontSize: "15px"
        },
        button: {
            minWidth: 144
        }
    })
);

const RealEstateInfoCard = () => {
    const classes = useStyles();
    const router = useRouter();

    const realEstate = useReduxSelector(getRealEstate);
    const currencySymbol = useReduxSelector(getCurrency);
    const options = useReduxSelector(getRealEstateOptions);

    return (
        <Card className={classes.infoCard}>
            <CardContent>
                {options.length > 0 ? (
                    <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={2} style={{ minHeight: 118 }}>
                        <Grid container justifyContent={"center"} item xs={12} xl={1}>
                            {/*<Avatar className={classes.avatar} />*/}
                            <HouseOutlined style={{ width: 32, height: 32 }} />
                        </Grid>
                        <Grid item xs={12} lg={8} xl={7}>
                            <Typography className={classes.cardTitleStyle}>Ihre Immobilie</Typography>
                            <Typography className={classes.cardTextStyle}>{parseAddress(realEstate.address)}</Typography>
                            <Typography className={classes.cardTextStyle}>{parseCategory(realEstate?.category)}</Typography>
                            <Typography className={classes.cardTextStyle}>
                                Vermarktungspreis:{" "}
                                {CurrencyShort(
                                    realEstate?.askingPrice?.priceAssistant || realEstate?.askingPrice?.evaluation || realEstate?.askingPrice?.estimate
                                )}{" "}
                                {currencySymbol}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={"flex-end"} item xs={12} lg={4} xl={4}>
                            <Button onClick={() => router.push("/price-assistant")} variant="contained" color="secondary" className={classes.button}>
                                Bewertung
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <CreateRealEstate />
                )}
            </CardContent>
        </Card>
    );
};

export default RealEstateInfoCard;
