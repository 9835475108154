import { Grid, Card, CardContent, Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import { useRouter } from "next/router";

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            padding: theme.spacing(3),
            maxWidth: 630
        },
        description: {
            fontSize: 16,
            fontWeight: 300,
            marginBottom: theme.spacing(2)
        },
        button: {
            backgroundColor: "#0071E3",
            border: "1px solid transparent",
            marginTop: theme.spacing(4),

            "&:hover": {
                backgroundColor: "#3898EC"
            }
        }
    })
);

const NotAllowedStep = ({ title, description = "", route = "", routeNmeame = "" }) => {
    const classes = useStyles();
    const router = useRouter();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.description}>{title}</Typography>
                <Typography className={classes.description}>{description}</Typography>

                {route && (
                    <Grid container justifyContent={"center"}>
                        <Button color={"primary"} variant={"contained"} className={classes.button} onClick={() => router.push(`/${route}`)}>
                            Zum {routeNmeame}
                        </Button>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default NotAllowedStep;
