import { Fragment } from "react";
import { PageFormat, PageHeader } from "@onpreo/components/src";
import { Grid, Box } from "@material-ui/core";
import AgentInfoCard from "./agent-info-card";
import RealEstateInfoCard from "./real-estate-info-card";
import NextStepsCard from "./next-steps-card";
import Footer from "../../components/footer";
import { getRealEstateOneClickDeal, getRealEstateOptions } from "../../store/selectors/real-estate.selectors";
import { useReduxSelector } from "../../store/selectors";
import GoToOneClickDeal from "../../components/one-click-deal/go-to-one-click-deal";
import { getContact } from "../../store/selectors/contact.selectors";
import { getBackground } from "../../store/selectors/workspace.selectors";

const GuestHome = () => {
    const currentDeal = useReduxSelector(getRealEstateOneClickDeal);
    const currentContact = useReduxSelector(getContact);
    const background = useReduxSelector(getBackground);
    const backgroundType = background && background.type;
    const imageOrColor = backgroundType === "color" ? background?.backgroundColor : background?.backgroundImage?.src;
    const options = useReduxSelector(getRealEstateOptions);

    return (
        <Fragment>
            <PageFormat slim imageOrColor={imageOrColor} type={backgroundType}>
                <PageHeader
                    title={`Willkommen ${currentContact?.meta?.name} in Ihrem Immobilien-Bereich`}
                    subtitle={"Hier finden Sie alle Informationen rund um Ihre Immobilie"}
                />
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs={12} sm={6}>
                        <AgentInfoCard />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RealEstateInfoCard />
                    </Grid>
                </Grid>
                {options.length > 0 ? (
                    <Fragment>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12}>
                                <NextStepsCard />
                            </Grid>
                        </Grid>
                        {!currentDeal?.dealConfirmed && <GoToOneClickDeal />}
                    </Fragment>
                ) : (
                    <Box mb={16} />
                )}
            </PageFormat>
            <Footer />
        </Fragment>
    );
};

export default GuestHome;
