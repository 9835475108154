import { standardAuthConfig } from "../src/utils/constants/standard-auth-config";
import GuestHome from "../src/pages/home";

export default function GuestProfile() {
    return <GuestHome />;
}

GuestProfile.authenticated = {
    ...standardAuthConfig
};
