import { Grid } from "@material-ui/core";
import NotAllowedStep from "../not-allowed-step";
import { OneClickDeal } from "./index";
import { NOT_ALLOWED_TEXT } from "../not-allowed-step/not-allowed-helper-text";

const GoToOneClickDeal = () => {
    return (
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
                <NotAllowedStep title={NOT_ALLOWED_TEXT.ONE_CLICK_DEAL.title} description={NOT_ALLOWED_TEXT.ONE_CLICK_DEAL.description} />
            </Grid>
            <Grid item xs={12} md={6}>
                <OneClickDeal variant={"teaser"} />
            </Grid>
        </Grid>
    );
};

export default GoToOneClickDeal;
