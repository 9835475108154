import { useMemo } from "react";
import { Avatar, Button, Card, CardContent, createStyles, Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import { useRouter } from "next/router";
import { useReduxSelector } from "../../store/selectors";
import { getWorkspaceAgentImage } from "../../store/selectors/workspace.selectors";
import { getAgentImage, getAgentName, getAgentPhone, getDefaultImage, getAgentGender } from "../../store/selectors/agent.selector";
import {
    getResponsibleUserName,
    getResponsibleUserPhone,
    getResponsibleUserImage,
    getResponsibleUserGender
} from "../../store/selectors/real-estate.selectors";

const useStyles = makeStyles(theme =>
    createStyles({
        infoCard: {
            width: "100%",
            minHeight: 150
        },
        avatar: {
            width: 80,
            height: 80,
            background: theme.palette.grey[300]
        },
        cardTextStyle: {
            fontWeight: 600,
            fontSize: "15px"
        },
        cardTextTelephoneStyle: {
            marginTop: theme.spacing(1),
            fontWeight: 400,
            fontSize: "15px"
        },
        phoneIcon: {
            width: 18,
            height: 18
        },
        button: {
            minWidth: 144
        }
    })
);

const AgentInfoCard = () => {
    const classes = useStyles();
    const router = useRouter();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isDesctop = useMediaQuery(theme.breakpoints.up("xl"));

    const agentImage = useReduxSelector(getAgentImage);
    const agentName = useReduxSelector(getAgentName);
    const agentPhone = useReduxSelector(getAgentPhone);
    const agentGender = useReduxSelector(getAgentGender);
    const defaultImage = useReduxSelector(getDefaultImage);

    const responsibleUserImage = useReduxSelector(getResponsibleUserImage);
    const responsibleUserName = useReduxSelector(getResponsibleUserName);
    const responsibleUserPhone = useReduxSelector(getResponsibleUserPhone);
    const responsibleUserGender = useReduxSelector(getResponsibleUserGender);

    const image = responsibleUserImage?.src || agentImage?.src || defaultImage?.src;

    const getSalutation = () => {
        let result;
        if (responsibleUserName) {
            responsibleUserGender === "Frau"
                ? (result = `Ihre Maklerin: ${responsibleUserName}`)
                : responsibleUserGender === "Herr"
                ? (result = `Ihr Makler: ${responsibleUserName}`)
                : (result = `Ihr/e Makler/-in: ${responsibleUserName}`);
        } else {
            agentGender === "Frau"
                ? (result = `Ihre Maklerin: ${agentName}`)
                : agentGender === "Herr"
                ? (result = `Ihr Makler: ${agentName}`)
                : (result = `Ihr/e Makler/-in: ${agentName}`);
        }

        return result;
    };

    return (
        <Card className={classes.infoCard}>
            <CardContent>
                <Grid container justifyContent={isDesctop ? "space-between" : "flex-end"} alignItems={"center"} spacing={2}>
                    <Grid container justifyContent={"center"} item xs={12} md={4} xl={2}>
                        <Avatar className={classes.avatar} src={image} />
                    </Grid>
                    <Grid container justifyContent={"flex-start"} item xs={12} md={8} xl={5} direction={"column"}>
                        <Typography className={classes.cardTextStyle}>{getSalutation()}</Typography>
                        <Typography className={classes.cardTextTelephoneStyle}>
                            <Phone className={classes.phoneIcon} /> Tel.: {responsibleUserPhone || agentPhone}
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={2} item xs={12} xl={5} direction={isTablet ? "row" : "column"}>
                        <Grid item>
                            <Button className={classes.button} variant="contained" color="secondary">
                                Kontakt
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.button} onClick={() => router.push("/real-estate-agent")} variant="contained" color="secondary">
                                Makler Infos
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AgentInfoCard;
